<template>
  <div class="wrapper">
    <el-container class="help-pc">
      <el-header> </el-header>

      <el-container>
        <el-aside width="260px">
          <el-menu :default-active="defaultActive" @select="selectMenu">
            <el-submenu
              v-for="(item, i) in menuList"
              :key="i"
              :index="String(i + 1)"
            >
              <template v-slot:title>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                v-for="(item2, i2) in item.children"
                :key="i2"
                :index="`${i + 1}-${i2 + 1}`"
              >
                {{ item2.title }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <el-image v-if="img" :src="img"></el-image>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {
  ElContainer,
  ElHeader,
  ElMenu,
  ElSubmenu,
  ElMenuItem,
  ElMain,
  ElAside,
  ElImage,
} from 'element-plus';
import 'element-plus/packages/theme-chalk/src/base.scss';

export default {
  name: 'helpPC',

  data() {
    return {
      student: [
        {
          title: '基本信息操作',
          children: [
            {
              title: '登录注册',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-60551606876199849.png',
            },
            {
              title: '设置用户信息',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-63041606876310811.png',
            },
            {
              title: '个人信息修改',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-79281606876234701.png',
            },
          ],
        },
        {
          title: '简历',
          children: [
            {
              title: '在线简历及附件简历操作说明',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-70241606876265826.png',
            },
          ],
        },
        {
          title: 'offer及三方协议',
          children: [
            {
              title: '查看offer及签定三方协议',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-96311606876366219.png',
            },
          ],
        },
      ],
      company: [
        {
          title: '基本信息操作',
          children: [
            {
              title: '登录注册',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-83531606875653501.png',
            },
            {
              title: '修改密码、换绑手机号',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-98141606875983449.png',
            },
            {
              title: '个人信息修改',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-85511606875761222.png',
            },
          ],
        },
        {
          title: '易校招产品',
          children: [
            {
              title: '预约宣讲会、双选会及VIP购买',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-41331606875805578.png',
            },
          ],
        },
        {
          title: '招聘管理',
          children: [
            {
              title: '发布职位，面试室管理及签订三方协议',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-2101606876152598.png',
            },
          ],
        },
        {
          title: '宣讲室管理',
          children: [
            {
              title: '如何上传及更新宣讲资料',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-36761606876104860.png',
            },
          ],
        },
        {
          title: '沟通消息',
          children: [
            {
              title: '如何查看沟通消息',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-15631606876047627.png',
            },
          ],
        },
        {
          title: '订单',
          children: [
            {
              title: '如何查看宣讲会、双选会及VIP订单',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-6451606875694817.png',
            },
          ],
        },
      ],
      teacher: [
        {
          title: '基本信息操作',
          children: [
            {
              title: '登录注册',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-92221606875017073.png',
            },
            {
              title: '设置用户信息',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-93141606875247756.png',
            },
            {
              title: '个人信息修改',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-91331606875095837.png',
            },
            {
              title: '本校学生基础信息',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-80721606875569138.png',
            },
          ],
        },
        {
          title: '本校双选会管理',
          children: [
            {
              title: '双选会看板与审核',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-36081606875319940.png',
            },
            {
              title: '双选会创建',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-49041606874892229.png',
            },
          ],
        },
        {
          title: '本校宣讲会管理',
          children: [
            {
              title: '宣讲会管理',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-36691606875512974.png',
            },
          ],
        },
        {
          title: '本校就业数据管理',
          children: [
            {
              title: '面试室管理、协议室管理及三方协议',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-19111606875148934.png',
            },
          ],
        },
        {
          title: '本校信息管理',
          children: [
            {
              title: '编辑、修改学校基础信息',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-84091606875456981.png',
            },
          ],
        },
        {
          title: '自定义消息',
          children: [
            {
              title: '如何给本校学生发送消息',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-91101606875380556.png',
            },
          ],
        },
        {
          title: '签到',
          children: [
            {
              title: '查看本校学生签到数据',
              image:
                'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-50771606875209260.png',
            },
          ],
        },
      ],
      img: '',
      defaultActive: '1-1',
    };
  },

  computed: {
    menuList() {
      const type = this.$route.params.type || 'student';

      return this[type];
    },
  },

  watch: {
    $route() {
      location.reload();
    },
  },

  mounted() {
    Array.from(['html', 'body', '#app']).forEach((item) =>
      this.setFullpage(item)
    );
    this.selectMenu('1-1');
  },

  beforeUnmount() {
    Array.from(['html', 'body', '#app']).forEach((item) =>
      this.removeFullPage(item)
    );
  },

  methods: {
    setFullpage(selector) {
      document.querySelector(selector).style = 'width: 100%; height: 100%;';
    },

    removeFullPage(selector) {
      document.querySelector(selector).removeAttribute('style');
    },

    selectMenu(index) {
      window.scrollTo(0, 0);

      if (typeof index !== 'string') return;

      const [i, i2] = index.split('-');

      if (!i || !i2) return;

      this.img = this.menuList[i - 1].children[i2 - 1].image;
      return;
    },
  },

  components: {
    ElContainer,
    ElHeader,
    ElMenu,
    ElSubmenu,
    ElMenuItem,
    ElMain,
    ElAside,
    ElImage,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: 100%;
  padding: 20px 0;
  background: #f8f8f8;
  box-sizing: border-box;
  overflow: auto;
}

.help-pc {
  width: 1200px;
  background: #fff;
  margin: 0 auto;
}

.el-header {
  padding: 0;
  width: 1200px;
  height: 150px !important;
  background: url('~@/assets/images/guide_pc.png');
  background-size: cover;
}

.el-aside {
  padding: 20px 0;
  overflow: hidden;
}

.el-menu {
  border-right: 0;
}

.el-menu-item {
  padding-left: 20px !important;
}

.help-pc :deep(.el-submenu__title) {
  font-size: 16px;
  font-weight: bold;
}
</style>
